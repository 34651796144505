.document {
	counter-reset: page;
}

.document__page {
	counter-increment: page;
	&::before {
		display: block;
		content: "Page " counter(page);
		margin-bottom: 2rem;
	}
	&:first-child:last-child {
		&::before {
			display: none;
		}
	}

	@extend %content;

	margin-top: 2rem;
	border-top: 2px solid;
	@include use-var(border-top-color, --accent, currentColor);
	padding-top: 1rem;
}

.document__withheld {
	@include contrast-dark;
	padding: 1rem;
}

.document__link {
	text-decoration: underline;
}
