@import "scaffolding";

@import "extendables/card";
@import "extendables/content";
@import "extendables/file-metadata";

@import "components/policy";
@import "components/version";
@import "components/duration";
@import "components/file";
@import "components/alt-file";
@import "components/accessibility";
@import "components/provenance";
@import "components/directory";
@import "components/policy-summary";
@import "components/document";
@import "components/pathway";
@import "components/home-hero";

@import "components/content-report";

body {
	@include contrast-primary;
}
