.policy-summary {
	padding: 1em 0;

	> :first-child {
		margin-top: 0;
	}
	> :last-child {
		margin-bottom: 0;
	}

	position: relative;

	border-bottom: 2px solid;
	@include use-var(border-bottom-color, --accent, currentColor);
}

.policy-summary__title {
	display: block;
	margin-bottom: 0.5em;

	@include font-heading-20b;
}

.policy-summary__link {
	@include link;
	.policy-summary--stub & {
		@include link-stub;
	}
}

%policy-summary__metadata {
	display: block;
	margin-bottom: 0.5em;

	@include font-base-18;
}

.policy-summary__type,
.policy-summary__date,
.policy-summary__stub-notice {
	@extend %policy-summary__metadata;
}

.policy-summary__previous-names {
	display: none;
	.policy-summary.show-previous-names & {
		@extend %policy-summary__metadata;
	}
}

.policy-summary__previous-names__title {
	@include font-base-18b;
}

.policy-summary__previous-names__list {
	@include list-reset;
}

.policy-summary__previous-names__item {}
