.version {}

.version__alternate-files {
	margin-top: 2rem;
}

.version__alternate-files__title {
	@include font-heading-28b;
	margin-bottom: 1rem;
}

.version__alternate-files__contents {}
