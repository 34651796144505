.directory {}

.directory__search {
	body.no-js & {
		display: none;
	}

	isolation: isolate;
}

.directory__search__row {
	display: flex;
	flex-wrap: wrap;
}

%directory__search__input {
	@include input-reset;
	@include font-base-18;
	@include contrast-secondary;

	min-height: 44px;

	padding: 0 0.5em;
	border: 1px solid;
	@include use-var(border-color, --accent, currentColor);

	position: relative;
	&:focus {
		z-index: 1;
	}

	@include bp-to("s") {
		width: 100%;
		&:not(:last-child) {
			border-bottom: 0;
		}
	}
	@include bp-from("s") {
		&:not(:last-child) {
			border-right: 0;
		}
	}
}

.directory__search__label {
	@include font-base-18;
	cursor: pointer;

	display: flex;
	align-items: center;
}

.directory__search__query {
	@extend %directory__search__input;

	flex-grow: 1;
}

.directory__search__type {
	@extend %directory__search__input;
}

.directory__search__fake-checkbox {
	cursor: pointer;

	width: rem(44px);
	height: rem(44px);
	display: flex;
	align-items: center;
}
.directory__search__fake-checkbox__input {
	@include visually-hidden;
}
.directory__search__fake-checkbox__display {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: rem(18px);
	width: 2em;
	height: 2em;

	box-shadow: inset 0 0 0 1px currentColor;
	box-shadow: inset 0 0 0 1px var(--accent);

	@include contrast-secondary;

	&::before {
		font-family: $font-icons;
	}

	.directory__search__fake-checkbox__input:focus-visible ~ & {
		@include focus-outline;
	}
	.directory__search__fake-checkbox__input:checked ~ & {
		&::before {
			content: $icon-tick;
		}
	}
}

.directory__search__submit {
	@include button($theme: "secondary");
}

.directory__search-results {}

.directory__search-summary {
	display: block;
	margin-top: 1rem;

	@include font-base-20;
}

.directory__search-summary__query {
	@include font-base-20b;
}

.directory__no-search-results {
	margin-top: 1rem;
	@include font-base-20;
}

.directory__list {
	@include list-reset;
}

.directory__item {}
