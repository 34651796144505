.policy {}

.policy__other-versions {}

.policy__other-versions__heading {
	@include font-heading-40b;
}

.policy__other-versions__list {
	@include list-reset;
}

.policy__other-versions__item {}

.policy__version {
	margin-top: 1rem;
	&:first-child {
		margin-top: 0;
	}
}

.policy__version__head {
	margin-bottom: 2rem;
}

.policy__version__body {}

.policy__version--disclosure {
	.policy__version__name {
		cursor: pointer;
	}

	&:not([open]) {
		margin-bottom: 1em;
	}
	&[open] {
		margin-bottom: 2rem;
	}
}

.policy__version__label {
	display: block;
	margin: 0.5rem 0;
	@include font-heading-20b;
}

.policy__version__name {
	margin: 0.5rem 0;
	@include font-heading-32b;
}

.policy__version__name-text {
	display: inline;
	@include font-heading-32b;
}

.policy__version__link {
	@include font-base-16;
	@include link;
}

.policy__version__details {}

.policy__older-versions-notice {
	margin-top: 2rem;

	@include font-base-20;
}
