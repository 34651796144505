.file {
	@extend %card;

	@supports (display: grid) {
		display: grid;
		grid-column-gap: 2rem;

		@include bp-to("s") {
			grid-template-areas:
				"handle"
				"notices"
				"accessibility"
				"metadata"
				"provenance"
				"alternate-files";
		}
		@include bp-from("s") {
			grid-template-areas:
				"handle handle"
				"notices notices"
				"accessibility provenance"
				"metadata provenance"
				"alternate-files alternate-files";
			// Equal columns
			grid-template-columns: repeat(2, minmax(0, 1fr));
			// Provenance can push row out, all others collapse to minimum height based on content
			grid-template-rows: repeat(3, min-content) 1fr min-content;
		}
	}

	+ .file {
		margin-top: 2rem;
	}
}

////////////
// HANDLE //
////////////
.file__handle {
	grid-area: handle;
}

.file__handle__link {
	@include font-base-24b;
	@include link;
}

.file__handle__size {
	@include font-base-16;
	white-space: nowrap;
}

.file__notices {
	margin-top: 1rem;
	grid-area: notices;
}

///////////////////
// ACCESSIBILITY //
///////////////////
.file__accessibility {
	margin-top: 1rem;
	grid-area: accessibility;
}

// See _accessibility.scss

//////////////
// METADATA //
//////////////
.file__metadata {
	@extend %file-metadata__section;
	grid-area: metadata;
}

.file__licence {}

.file__licence__handle {
	@extend %file-metadata;
	@include font-base-18;
}

.file__licence__handle__icon {
	@extend %file-metadata__part;

	@include icon-indicator($icon-info);

	.file__licence[data-licence-name="None"] & {
		@include icon-indicator($icon-warning);
	}

	// Creative Commons
	.file__licence[data-licence-name^="CC"] & {
		@include icon-indicator($icon-tick);
	}
}

.file__licence__handle__text {
	@extend %file-metadata__part;
}

.file__licence__handle__link {
	@include link;
}

.file__licence__notices {
	margin: 0.5em 0;
}

.file__original {
	@extend %file-metadata;

	@extend %file-metadata__section;
	@include font-base-18;
}

.file__original__icon {
	@extend %file-metadata__part;

	.file__original--original & {
		@include icon-indicator($icon-tick);
	}
	.file__original--not-original & {
		@include icon-indicator($icon-info);
	}
}
.file__original__text {
	@extend %file-metadata__part;
}

.file__modifications {
	@extend %file-metadata__details;
}

.file__modifications__item {
	@extend %file-metadata__details__item;
	grid-template-columns: 1fr;

	@include font-base-18;
}

.file__incomplete {
	@extend %file-metadata;

	@extend %file-metadata__section;
	@include font-base-18;
}

.file__incomplete__icon {
	@extend %file-metadata__part;

	@include icon-indicator($icon-warning);
}

.file__incomplete__text {
	@extend %file-metadata__part;
}

////////////////
// PROVENANCE //
////////////////
.file__provenance {
	margin-top: 1rem;
	grid-area: provenance;
}

// See _provenance.scss

/////////////////////
// ALTERNATE FILES //
/////////////////////
.file__alternate-files {
	margin-top: 1rem;
	border-top: 2px solid;
	@include use-var(border-top-color, --accent, currentColor);
	padding-top: 1rem;
	grid-area: alternate-files;
}

.file__alternate-files__title {
	@include font-heading-22b;
	margin: 0 0 0.5rem;
}
