.provenance__card {
	@extend %card;

	margin: 2rem 0;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.provenance {
	display: block;
	margin: 0.5rem 0;
	padding-bottom: 0.5rem;

	border-bottom: 1px solid;
	@include use-var(border-bottom-color, --accent, currentColor);

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		padding-bottom: 0;
		border-bottom: 0;
		margin-bottom: 0;
	}
}

%provenance__meta {
	display: block;
	@extend %file-metadata__section;
	&:first-child {
		margin-top: 0;
	}

	@include font-base-18;
}

.provenance__source {
	@extend %provenance__meta;
}

.provenance__source__link {
	@include link;
}

.provenance__date {
	@extend %provenance__meta;
}

.provenance__withholdings {
	@extend %provenance__meta;
}

.provenance__withholdings__summary {
	@extend %file-metadata;
	@include font-base-18;
}
summary.provenance__withholdings__summary {
	@extend %file-metadata--summary;
}

.provenance__withholdings__summary-icon {
	@extend %file-metadata__part;

	.provenance__withholdings--pass & {
		@include icon-indicator($icon-tick);
	}
	.provenance__withholdings--undetermined & {
		@include icon-indicator($icon-warning);
	}
	.provenance__withholdings--fail & {
		@include icon-indicator($icon-error);
	}
}

.provenance__withholdings__summary-text {
	@extend %file-metadata__part;
}

.provenance__withholdings__summary-expand-icon {
	@extend %file-metadata__expand-icon;
}

.provenance__withholdings__list {
	@extend %file-metadata__details;
}

.provenance__withholding {
	@extend %file-metadata__details__item;
	align-items: flex-start;

	@supports (display: grid) {
		grid-template-columns: 9ch 1fr;
	}
}

.provenance__withholding__type {
	@extend %file-metadata__detail__item__column;
	white-space: nowrap;
}

.provenance__withholding__link {
	@extend %file-metadata__detail__item__column;
	@include link;
}

.provenance__withholding__value {
	@extend %file-metadata__detail__item__column;
}
