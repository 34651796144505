@use "sass:math";

$duration-bp: "s";

.duration {
	@include list-reset;
	display: flex;
	align-items: stretch;
	justify-content: center;

	@include bp-to($duration-bp) {
		flex-direction: column;
		align-items: center;
	}

	margin: 2em 0;

	page-break-inside: avoid;
}

$duration-sep-length: 2ch;
$duration-sep-thickness: rem(2px);
$duration-sep-gap: 1ch;

$duration-sep-margin: calc(#{$duration-sep-length} + #{$duration-sep-gap * 2});
$duration-sep-offset: calc(100% + #{$duration-sep-gap});

.duration__item {
	display: flex;
	flex-direction: column;

	text-align: center;

	position: relative;
	@include font-base-20;

	&::after {
		content: "";
		position: absolute;

		@include use-var(background-color, --accent, currentColor);
		print-color-adjust: exact;
		-webkit-print-color-adjust: exact;
	}

	&:last-child {
		&::after {
			display: none;
		}
	}

	@include bp-to($duration-bp) {
		margin-bottom: $duration-sep-margin;
		&:last-child {
			margin-bottom: 0;
		}
		&::after {
			top: $duration-sep-offset;
			left: calc(50% - #{math.div($duration-sep-thickness, 2)});

			height: $duration-sep-length;
			width: $duration-sep-thickness;
		}
	}
	@include bp-from($duration-bp) {
		margin-right: $duration-sep-margin;
		&:last-child {
			margin-right: 0;
		}
		&::after {
			top: calc(0.5em - #{math.div($duration-sep-thickness, 2)});
			left: $duration-sep-offset;

			width: $duration-sep-length;
			height: $duration-sep-thickness;
		}
	}
}

.duration__item-date {
	display: block;
	flex-grow: 1;
	@include font-base-20;
	@include bp-from($duration-bp) {
		line-height: #{math.div(20, 20)};
	}
	white-space: nowrap;
}

.duration__item-status {
	display: block;
	margin-top: 0.2em;
	@include font-base-16;
}

.duration__item--small {
	.duration__item-date {
		@include font-base-16;
		@include bp-from($duration-bp) {
			line-height: #{math.div(20, 16)};
		}
	}
}
