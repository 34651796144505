.accessibility {}

.accessibility__summary {
	@extend %file-metadata--summary;
}

.accessibility__summary__icon {
	@extend %file-metadata__part;

	.accessibility__summary--good & {
		@include icon-indicator($icon-tick);
	}
	.accessibility__summary--poor &,
	.accessibility__summary--undetermined & {
		@include icon-indicator($icon-warning);
	}
	.accessibility__summary--bad &,
	.accessibility__summary--none & {
		@include icon-indicator($icon-cross);
	}
}

.accessibility__summary__label {
	@extend %file-metadata__part;
	@include font-base-18b;
}

.accessibility__summary__rating {
	@extend %file-metadata__part;
	@include font-base-18;
}

.accessibility__summary__expand-icon {
	@extend %file-metadata__expand-icon;
}

.accessibility__details {}

.accessibility__feature-list {
	@extend %file-metadata__details;
}

.accessibility__feature-item {
	@extend %file-metadata__details__item;
}

.accessibility__feature-name {
	@extend %file-metadata__detail__item__column;
	@extend %file-metadata;
}

.accessibility__feature-icon {
	@extend %file-metadata__part;

	.accessibility__feature-item--true & {
		@include icon-indicator($icon-tick);
	}
	.accessibility__feature-item--partial &,
	.accessibility__feature-item--undetermined & {
		@include icon-indicator($icon-warning);
	}
	.accessibility__feature-item--false & {
		@include icon-indicator($icon-cross);
	}
}

.accessibility__feature-name-text {
	@include font-base-18b;
}

.accessibility__feature-value {
	@extend %file-metadata__detail__item__column;
	@include font-base-18;
}

.accessibility__feature-note {
	width: 100%;
	grid-column: 1 / span 2;
	@include font-base-16;
}
